<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 sm9 md10 text-left>
        <span class="mainHeader">HSN CODE LIST</span>
      </v-flex>
      <!-------------------------ADD CUSTOMER TO SCHEME----------------------------->
      <v-flex xs12 sm3 md2 class="buttons1" pt-2 pt-sm-0 text-right>
        <v-btn dark color="#3F053C" @click="adddialog = true" class="buttons1">
          <v-icon small>mdi-plus</v-icon> &nbsp; Add HSN
        </v-btn>
      </v-flex>
       <!-------------------------LIST OF CUSTOMER IN SCHEME----------------------------->
      <v-flex xs12 py-6 text-left>
        <v-card
          class="rounded-lg"
          elevation="0"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4 v-if="CodeList">
            <v-flex xs12 v-if="CodeList.length > 0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left tablefont"><b>S.No.</b></th>
                      <th class="text-left tablefont"><b>Code</b></th>
                      <th class="text-left tablefont"><b>Name</b></th>
                      <th class="text-left tablefont"><b>Description</b></th>
                      <th class="text-left tablefont">
                        <b>Slab1 %</b>
                      </th>
                      <th class="text-left tablefont"><b>Action</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, i) in CodeList" :key="i" class="table">
                      <!-- <td>{{ i + 1 }}</td> -->
                      <td>
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                      <td>
                        <span v-if="value.hsnCode">{{
                          value.hsnCode
                        }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.name">{{
                          value.name
                        }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.hsnDescription">{{
                          value.hsnDescription
                        }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.gstSlab1Percentage">{{
                          value.gstSlab1Percentage
                        }}</span>
                        <span v-else>-</span>
                      </td>
                      <td class="text-center">
                         <v-layout wrap>
                          <v-flex xs6>
                            <v-icon
                            size="18"
                            color="#3F053C"
                            style="cursor: pointer"
                            @click="(deletedialog = true), (DeleteId = value._id)"
                            >mdi-delete</v-icon
                          >
                          </v-flex>
                          <v-flex xs6>
                            <v-icon
                          size="18"
                          color="#3F053C"
                          style="cursor: pointer"
                          @click="(dialogedit = true), (editItem = value)"
                          >mdi-pencil</v-icon
                        >
                          </v-flex>
                         </v-layout>
                        </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
            <v-flex xs12 v-else pa-4 text-center class="tablefont1"> No data found </v-flex>
          </v-layout>
        </v-card>
        <v-layout wrap v-if="CodeList">
          <v-flex xs12 pt-4 v-if="CodeList.length > 0">
            <v-pagination
              small
              color="#3F053C"
              v-model="page"
              :length="Pagelength"
            >
            </v-pagination>
          </v-flex>
        </v-layout>
        <v-dialog width="400px" v-model="deletedialog">
            <v-card width="400px" class="pa-2">
             
              <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Delete</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="deletedialog = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
              <v-card-text class="pa-4"
                >Are you sure you want to delete this HSN Code?
              </v-card-text>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <!-- <v-btn
                  color="grey"
                  text
                  class="button1"
                  @click="deletedialog = false"
                  >Cancel</v-btn
                > -->
                <v-btn
                  color="#3F053C"
                  class="button1"
                  dark
                  @click="deleteHSNCode(DeleteId)"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
      <!-------------------------ADD CUSTOMER TO SCHEME DIALOG----------------------------->
        <v-dialog width="800px" v-model="adddialog">
          <v-form v-model="addcat" ref="addcat" @submit.prevent>
            <v-card tile width="1000px" class="px-4 py-4 rounded-lg">
              <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Add New HSN</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                    @click="(adddialog = false)(resetForm())"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm6>
                  <v-layout wrap pb-4>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">HSN Code</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                          :rules="[rules.required]"
                          type="text"
                          v-model="newHSNcode"
                          outlined
                          dense
                          color="#b234a9"
                          hide-details="auto"
                        ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Name</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            :rules="[rules.required]"
                            type="text"
                            placeholder="Enter HSN name"
                            v-model="newHSNname"
                            outlined
                            dense
                            color="#b234a9"
                            hide-details="auto"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Slab Percentage</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                          :rules="[rules.required]"
                          type="number"
                          v-model="newHSNpercent"
                          outlined
                          dense
                          color="#b234a9"
                          hide-details="auto"
                        ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-layout wrap pb-4>
                   
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Description</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-textarea
                          type="text"
                          v-model="newHSNdescription"
                          outlined
                          dense
                          color="#b234a9"
                          hide-details="auto"
                        ></v-textarea>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap pb-4 justify-end>
                <v-spacer></v-spacer>
                <v-flex xs2 text-right>
                  <v-layout wrap px-4 justify-end fill-height>
                    <v-flex xs12 text-center align-self-end class="buttons1">
                      <v-btn
                        type="submit"
                        color="#3F053C"
                        dark
                        block
                        @click="AddHsn()"
                        ><v-icon>mdi-plus</v-icon>Add</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-form>
        </v-dialog>
        <v-dialog width="800px" v-model="dialogedit">
          <v-form v-model="addcat" ref="addcat" @submit.prevent>
            <v-card tile width="1000px" class="px-4 py-4 rounded-lg">
              <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Edit HSN</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                    @click="(dialogedit = false)(resetForm())"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm6>
                  <v-layout wrap pb-4>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">HSN Code</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                          :rules="[rules.required]"
                          type="text"
                          v-model="editItem.hsnCode"
                          outlined
                          dense
                          color="#b234a9"
                          hide-details="auto"
                        ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Name</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            :rules="[rules.required]"
                            type="text"
                            placeholder="Enter HSN name"
                            v-model="editItem.name"
                            outlined
                            dense
                            color="#b234a9"
                            hide-details="auto"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Slab Percentage</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                          :rules="[rules.required]"
                          type="number"
                          v-model="editItem.gstSlab1Percentage"
                          outlined
                          dense
                          color="#b234a9"
                          hide-details="auto"
                        ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-layout wrap pb-4>
                   
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Description</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-textarea
                          type="text"
                          v-model="editItem.hsnDescription"
                          outlined
                          dense
                          color="#b234a9"
                          hide-details="auto"
                        ></v-textarea>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap pb-4 justify-end>
                <v-spacer></v-spacer>
                <v-flex xs2 text-right>
                  <v-layout wrap px-4 justify-end fill-height>
                    <v-flex xs12 text-center align-self-end class="buttons1">
                      <v-btn
                        type="submit"
                        color="#3F053C"
                        dark
                        block
                        @click="editHsn()"
                        ><v-icon>mdi-pencil</v-icon>Edit</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-form>
        </v-dialog>
        </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      deletedialog: false,
      DeleteId: null,
      editItem: {},
      showSnackBar: false,
      ServerError: false,
      appLoading: false,
      CodeList: [],
      newHSNpercent: null,
      newHSNcode: null,
      newHSNname: null,
      newHSNdescription: null,
      page:1,
      // page: 0,
      currentPage: 1,
      adddialog: false,
      dialogedit: false,
      //   curItem: {},
      msg: null,
      
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      curItem: {
        gstSlab2Percentage: 0,
        gstSlab2MinimumPrice: 0,
      },
    };
  },

  watch: {
    currentPage() {
      this.getData();
    },
  },

  mounted() {
    this.getData();
  },

  methods: {
    deleteHSNCode(id) {
      axios({
        url: "/hsncode/delete/" + id,
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
          this.showSnackBar = true;
          this.deletedialog = false;
          this.msg = response.data.msg;
          this.DeleteId = 0;
          this.appLoading = false;
          this.getData();
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },

    AddHsn() {
      this.appLoading = true;
      axios({
        url: "/hsncode/add",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data:{
          name:this.newHSNname,
          hsnCode:this.newHSNcode,
          hsnDescription:this.newHSNdescription,
          gstSlab1Percentage:this.newHSNpercent,
        },
      })
        .then((response) => {
          this.showSnackBar = true;
          this.adddialog = false;
          this.msg = response.data.msg;
          this.newHSNpercent= null;
      this.newHSNcode= null;
      this.newHSNname= null;
      this.newHSNdescription= null;
          this.appLoading = false;
          this.getData();
        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/hsnCodes/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          limit: 50,
          searchKeyword: "",
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.CodeList = response.data.data;
          this.Pagelength = Math.ceil(response.data.totalLength / 50);
        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },

    editHsn() {
      axios({
        url: "/hsncode/edit",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.editItem._id,
          hsnCode: this.editItem.hsnCode,
          name: this.editItem.name,
          hsnDescription: this.editItem.hsnDescription,
          gstSlab1Percentage: this.editItem.gstSlab1Percentage,
          // gstSlab2Percentage: editItem.gstSlab2Percentage,
          // gstSlab2MinimumPrice: editItem.gstSlab2MinimumPrice,
        },
      })
        .then((response) => {
          this.dialogedit = false;
          this.showSnackBar = true;
          this.msg = response.data.msg;
          this.getData();
        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  <style scoped>
.v-text-field input {
  font-family: kumbhBold;
}
</style>
  